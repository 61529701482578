<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 255px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center"
                        type="index"
                         width="55" label="#" >
      </el-table-column>
      <el-table-column prop="logo" width="90" label="图标" >
        <template #default="scope">
          <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
            <el-image
                v-if="scope.row.icon"
                style="width: 50px; height: 50px"
                :src="scope.row.icon"
                :preview-src-list="[scope.row.icon]">
<!--              <template #error>-->
<!--                <div style="font-size: 24px;font-weight: 400" class="el-icon-picture-outline">-->
<!--                </div>-->
<!--              </template>-->
            </el-image>
          </div>
        </template>
      </el-table-column>
        <el-table-column prop="name" label="标签名称" >
          <template #default="scope">
            <el-tag size="mini" >{{scope.row.name}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="110">
          <template #header>
            <el-button size="mini"  @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
          </template>
          <template #default="scope">
            <div style="margin: 5px 0">
              <el-button-group>
                <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
                <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>

      </div>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="30%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
      >
        <el-form-item label="图标" prop="icon">
          <div style="height: 100px">
            <el-upload
                style=" width: 180px;height: 180px"
                class="avatar-uploader"
                :action="UpURL"
                :show-file-list="false"
                :http-request="httpRequest"
                :before-upload="beforeAvatarUpload">
              <img v-if="ruleForm.icon" :src="ruleForm.icon" class="avatar" />
              <i v-else  class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <p style="color: #999999"><span style="font-size: 16px" class="el-icon-info"> </span>图片尺寸宽100 X 高100</p>
        </el-form-item>
        <el-form-item label="标签名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/productapiitem"
import {getIMG, getUQIMGurl} from "@/api/common";
//本页面接口
export default {
  name: 'brand',
  data(){
    return{
      login:false,
      drawer:false,
      tableData:[],
      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{
        name:'',
      },
      rules:{
        name: [
          { required: true, message: '请填写名称', trigger: 'change' },
        ],
      }
    }
  },
  created() {
    this.onTable()
    this.keyupSubmit()
  },

  methods:{
    //上传图片方法
    httpRequest(options) {
      this.login=true
      console.log('options',options.file,options.file.type)
      return new Promise((resolve, reject) => {
        let src=options.file.type
        getUQIMGurl({bucket:'image',ext:'.'+src.substr(6)}).then((res)=>{
          console.log('获取URL',res)
          if(res.code==0){
            this.UpURL=res.data.upload_url
            let url=res.data.preview_url
            console.log(this.UpURL)
            getIMG(options.file,this.UpURL,options.file.type).then((res)=>{
              resolve(res)
              this.ruleForm.icon=url
              this.login=false
            }).catch((err)=>{
              reject(err)
              this.login=false
            })

          }else {
            this.$message.error('获取上传地址失败！！')
            this.login=false
          }
        })

      })
    },

    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'||
          file.type === "image/png" ||
          file.type === "image/jpg"||
          file.type === "image/gif";
      // const isLt2M = file.size / 1024 / 1024 < 4
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG、JPEG、PNG、 格式!')
      }
      // if (!isLt2M) {
      // this.$message.error('上传头像图片大小不能超过 4MB!')
      // }
      return isJPG
      // && isLt2M
    },
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
        axios.getproductTagData(form).then((res)=>{
          console.log(res)
          this.tableData=res.data.data
          console.log('this.tableData',this.tableData)
          this.total=res.data.total
        }).catch((err)=>{

        })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
      this.ruleForm={
        name:'',
      }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.name=row.name
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postaddProductTag(this.ruleForm).then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditProductTag(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }

        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelProductTag({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },

  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
    padding: 0;
}
</style>